
import React, { useState } from 'react';
import NetworkingForm from '../components/NetworkingForm';
import SubmissionComplete from '../components/SubmissionComplete';

const PanIndex = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmitComplete = () => {
    setIsSubmitted(true);
    // Scroll to top
    // window.scrollTo(0, 0);
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {isSubmitted ? (
        <SubmissionComplete />
      ) : (
        <NetworkingForm onSubmitComplete={handleSubmitComplete} />
      )}
    </div>
  );
};

export default PanIndex;
