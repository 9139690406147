import React from "react";
// import { Globe } from "lucide-react";
import { useNavigate } from "react-router-dom";

const FormHeader = () => {
  const navigate = useNavigate();

  return (
    <div  id='register-pan' className="py-8 px-4 sm:px-6 lg:px-8 mb-6 rounded-lg text-dark">
      <div className="max-w-3xl mx-auto flex items-center gap-3">
        {/* <Globe size={40} className="text-white" /> */}
        <div>
          <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl font-bold">
            Interested in joining PAN?<a href="membership">Complete the form here…</a>
          </h1>
          {/* <p className="mt-2 text-white/90">Networking Form</p> */}
        </div>
      </div>
    </div>
  );
};

export default FormHeader;
