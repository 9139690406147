
import React from 'react';
// import { Award, Calendar, Globe, Users, Briefcase, Star } from "lucide-react";

const benefitsData = [
  {
    icon: (
      <svg className="h-10 w-10 text-purple-500 mb-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="9" cy="7" r="4"></circle>
        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
      </svg>
    ),
    title: "Networking Excellence",
    description: "Connect with industry professionals and build meaningful relationships that drive business growth."
  },
  {
    icon: (
      <svg className="h-10 w-10 text-purple-500 mb-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 2l3 7h7l-5 5 2 7-7-4-7 4 2-7-5-5h7z"></path>
      </svg>
    ),
    title: "Educational Programs",
    description: "Access specialized tracks tailored to your networking needs and professional development goals."
  },
  {
    icon: (
      <svg className="h-10 w-10 text-purple-500 mb-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="2" y="7" width="20" height="14" rx="2"></rect>
        <path d="M16 3h-8a2 2 0 0 0-2 2v2h12V5a2 2 0 0 0-2-2z"></path>
      </svg>
    ),
    title: "Business Opportunities",
    description: "Expand your reach through our partner network and gain exclusive business development opportunities."
  },
  {
    icon: (
      <svg className="h-10 w-10 text-purple-500 mb-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="3" y="4" width="18" height="18" rx="2"></rect>
        <path d="M16 2v4"></path>
        <path d="M8 2v4"></path>
        <path d="M3 10h18"></path>
      </svg>
    ),
    title: "Events Strategy",
    description: "Learn best practices for organizing, managing, and maximizing the value of networking events."
  },
  {
    icon: (
      <svg className="h-10 w-10 text-purple-500 mb-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polygon points="12 2 15 8 22 9 17 14 18 21 12 18 6 21 7 14 2 9 9 8"></polygon>
      </svg>
    ),
    title: "Member Recognition",
    description: "Gain visibility and recognition through our member spotlight and editorial opportunities."
  },
  {
    icon: (
      <svg className="h-10 w-10 text-purple-500 mb-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="2" y1="12" x2="22" y2="12"></line>
        <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10"></path>
      </svg>
    ),
    title: "Global Connections",
    description: "Access a worldwide network of professionals committed to excellence in professional networking."
  }
];

const Benefits = () => {
  return (
    <section id="benefits" className="py-12 md:py-20 px-4 md:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Member Benefits</h2>
          <p className="text-lg text-muted-foreground max-w-3xl mx-auto">
            Discover how PAN Global Network membership can 
            <br />
            transform your networking experience and professional growth.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {benefitsData.map((benefit, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-sm border hover:shadow-md transition-shadow">
              {benefit.icon}
              <h3 className="text-xl font-bold mb-2">{benefit.title}</h3>
              <p className="text-muted-foreground">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
