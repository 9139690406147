import React from 'react';
import LandingPage from './components/LandingPage';
import WhoWeAre from './components/WhoWeAre';
import PanForm from './components/pan-form';
import OurCampaign from './components/OurCampaign';
import LatestNews from './components/LatestNews';
// import Testimonials from './components/Testimonials';
import ActionSection from './components/ActionSection';
import NeedHelp from './components/NeedHelp';
import Footer from './components/Footer';
import Header from './components/Header';
import ContactUs from './components/ContactUs';
import Team from './components/Team';
// import PanIndex from './register-components/pages/pan-index';
import NetworkIndex from './network-components/pages/Network-Index';
import { Toaster } from 'react-hot-toast';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for the NetworkIndex page */}
        <Route path="/membership" element={<NetworkIndex />} />

        {/* Default Route (everything else remains as a single page) */}
        <Route
          path="/*"
          element={
            <div className="App">
              <Header />
              <div className="p-6">
                <div className="main-content">
                  <Toaster position="top-right" reverseOrder={false} />
                  <LandingPage />
                  <LatestNews />
                  {/* <NetworkIndex /> */}
                  <WhoWeAre />
                  {/* <PanForm /> */}
                  <OurCampaign />
                  {/* <Testimonials /> */}
                  <ActionSection />
                  <NeedHelp />
                  <Team />
                  <ContactUs />
                  <Footer />
                </div>
              </div>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
