
import React from 'react';

const FormSection = ({ title, children }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-6">
      <h2 className="text-xl font-semibold text-purple-800 mb-4 border-b pb-2">{title}</h2>
      {children}
    </div>
  );
};

export default FormSection;
