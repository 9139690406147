import { apiUrl } from '../environment/env';
import toast from 'react-hot-toast';

// Function to subscribe email
export const subscribeEmail = async (sEmail) => {
    try {
        const response = await fetch(`https://origin-staging.mi-recall.com/organiser-api/api/v1/pan-global/subuscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ sEmail })
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error subscribing email:", error);
    }
};

// Function to get all subscribers
export const getSubscribers = async () => {
    try {
        const response = await fetch(`https://origin-staging.mi-recall.com/organiser-api/api/v1/pan-global/get-subuscribers`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching subscribers:", error);
    }
};
// Function to add contact us message
export const addContactUs = async (sName, sEmail, sDescription) => {
    try {
        const response = await fetch(`https://origin-staging.mi-recall.com/organiser-api/api/v1/pan-global/add-contact-us`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ sName, sEmail, sDescription })
        });

        const data = await response.json();

        if (response.ok) {
            toast.success("Message sent successfully!", { duration: 5000 });
        } else {
            toast.error(data.message || "Failed to send message.", { duration: 5000 });
        }

        return data;
    } catch (error) {
        console.error("Error adding contact us message:", error);
        toast.error("Something went wrong. Please try again.", { duration: 5000 });
    }
};

export const addRegisterPanForm = async (formData) => {
    try {
        const response = await fetch(`https://origin-staging.mi-recall.com/organiser-api/api/v1/pan-global/register-panglobal`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData) // Sends form data dynamically
        });

        const data = await response.json();

        if (response.ok) {
            toast.success("Thank you for your submission. We'll be in touch soon.", { duration: 5000 });
        } else {
            toast.error(data.message || "Failed to submit registration.", { duration: 5000 });
        }

        return data;
    } catch (error) {
        console.error("Error submitting registration form:", error);
        toast.error("Something went wrong. Please try again.", { duration: 5000 });
    }
};

// Function to get all contact us messages
export const getContactUsMessages = async () => {
    try {
        const response = await fetch(`${apiUrl}/get-contact-us`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching contact us messages:", error);
    }
};
