import React, { useState,useEffect } from "react";
import "../styles/LatestNews.css";
import { subscribeEmail } from "../services/api.service"; // Import the API service
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA
import { Helmet } from "react-helmet";



const LatestNews = () => {
  const [sEmail, setEmail] = useState("");
  const [responseMessage, setResponseMessage] = useState(""); // For success/error messages
  const [captchaVerified, setCaptchaVerified] = useState(false); // For CAPTCHA verification
  const [captchaSuccess, setCaptchaSuccess] = useState(false); // For green check display
  const [showCaptcha, setShowCaptcha] = useState(true); // For showing/hiding the CAPTCHA

  const handleInputChange = (e) => {
    setEmail(e.target.value); // Update the email state when the input changes
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true); // Set CAPTCHA as verified
      setCaptchaSuccess(true); // Show the green check icon

      // Set a timeout to hide the CAPTCHA after 5 seconds
      setTimeout(() => {
        setShowCaptcha(false);
      }, 5000);
    } else {
      setCaptchaVerified(false);
      setCaptchaSuccess(false);
    }
  };

  const handleSubmit = async () => {
    if (!sEmail) {
      setResponseMessage("Please enter a valid email.");
      return;
    }

    if (!captchaVerified) {
      setResponseMessage("Please verify you are human.");
      return;
    }

    try {
      const response = await subscribeEmail(sEmail); // Call the subscribe API with the email
      if (response?.message) {
        setResponseMessage("Subscribed successfully!");
        setEmail(""); // Reset the email input after successful subscription
        setCaptchaVerified(false); // Reset CAPTCHA verification
        setCaptchaSuccess(false); // Hide green check icon
        setShowCaptcha(true); // Reset CAPTCHA visibility for the next submission
      } else {
        setResponseMessage("Subscription failed. Please try again.");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setResponseMessage("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    const event = new Event("ml:load");
    window.dispatchEvent(event);
  }, []);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://assets.mailerlite.com/js/universal.js"
        ></script>
        <script>
          {`window.ml=window.ml||function(){(window.ml.q=window.ml.q||[]).push(arguments)};
          window.ml('account', '1254729');`}
        </script>
      </Helmet>
      <div className="ml-embedded" data-form="YDiueH"></div>
    </>
  );
  // return (
  //   <div className="latest-news">
  //     <div className="newsletter">
  //       <div>
  //         <h2 className="newsletter-header">Subscribe</h2>
  //         <p className="newsletter-body">Subscribe for Updates.</p>
  //       </div>
  //       <div className="newsletter-subscribe">
  //         <input
  //           type="email"
  //           placeholder="Your Email"
  //           value={sEmail} // Controlled input tied to state
  //           onChange={handleInputChange} // Update state on input change
  //         />
  //         <button onClick={handleSubmit}>Subscribe</button>
  //       </div>

  //       {/* reCAPTCHA Integration */}
  //       {showCaptcha && (
  //         <div className="captcha-container">
  //           <ReCAPTCHA
  //             sitekey="6LfP-DwqAAAAAH69YxuWI1aL2uafHvyg0Wu_Wr1F" // Replace with your reCAPTCHA site key
  //             onChange={handleCaptchaChange} // Handle CAPTCHA changes
  //           />
  //           {captchaSuccess && <span className="captcha-check"></span>}
  //         </div>
  //       )}

  //       {/* Display success or error message */}
  //       {responseMessage && <p className="response-message">{responseMessage}</p>}
  //     </div>
  //   </div>

  // );
};

export default LatestNews;
