import React, { useState } from 'react';
import { CheckCircle, User, Building, MapPin, Users, Calendar, Link, ArrowRight, ArrowLeft } from 'lucide-react';
import FormHeader from './FormHeader';
import FormSection from './FormSection';
import ContactOption from './ContactOption';
import NetworkingEventOption from './NetworkingEventOption';
import ConfirmationModal from './ConfirmationModal';
import FormStepper from './FormStepper';
import toast from 'react-hot-toast';
// import useToast from '../hooks/use-toast';
import { addRegisterPanForm } from '../../services/api.service'; // Import the API service

const NetworkingForm = ({ onSubmitComplete }) => {
  // const { toast } = useToast();
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 4;
  const [showConfirmation, setShowConfirmation] = useState(false);
  
  const [formData, setFormData] = useState({
    name: '',
    isRepresentingBusiness: false,
    businessName: '',
    businessNature: '',
    businessLocation: '',
    staffCount: '',
    contractorCount: '',
    contactMethods: {
      Mobile: { selected: false, value: '' },
      Landline: { selected: false, value: '' },
      Email: { selected: false, value: '' },
      WhatsApp: { selected: false, value: '' },
      LinkedIn: { selected: false, value: '' },
      Text: { selected: false, value: '' },
      Other: { selected: false, value: '' }
    },
    howFound: '',
    isNetworkingCritical: null,
    networkingEvents: {
      'Local Networking Events': { selected: false, count: '' },
      'National Events': { selected: false, count: '' },
      'International Events': { selected: false, count: '' }
    },
    additionalComments: ''
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleContactMethodChange = (method) => {
    setFormData(prev => ({
      ...prev,
      contactMethods: {
        ...prev.contactMethods,
        [method]: {
          ...prev.contactMethods[method],
          selected: !prev.contactMethods[method].selected
        }
      }
    }));
  };

  const handleContactValueChange = (method, value) => {
    setFormData(prev => ({
      ...prev,
      contactMethods: {
        ...prev.contactMethods,
        [method]: {
          ...prev.contactMethods[method],
          value
        }
      }
    }));
  };

  const handleNetworkingEventChange = (event) => {
    setFormData(prev => ({
      ...prev,
      networkingEvents: {
        ...prev.networkingEvents,
        [event]: {
          ...prev.networkingEvents[event],
          selected: !prev.networkingEvents[event].selected
        }
      }
    }));
  };

  const handleEventCountChange = (event, value) => {
    setFormData(prev => ({
      ...prev,
      networkingEvents: {
        ...prev.networkingEvents,
        [event]: {
          ...prev.networkingEvents[event],
          count: value
        }
      }
    }));
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value === 'true'
    }));
  };

  const validateCurrentStep = () => {
    const newErrors = {};

    if (currentStep === 0) {
      if (!formData.name.trim()) {
        newErrors.name = 'Name is required';
      }
      
      if (formData.isRepresentingBusiness) {
        if (!formData.businessName.trim()) {
          newErrors.businessName = 'Business name is required';
        }
        if (!formData.businessNature.trim()) {
          newErrors.businessNature = 'Business nature is required';
        }
        if (!formData.businessLocation.trim()) {
          newErrors.businessLocation = 'Business location is required';
        }
        if (!formData.staffCount) {
          newErrors.staffCount = 'Staff count is required';
        }
        if (!formData.contractorCount) {
          newErrors.contractorCount = 'Contractor count is required';
        }
      }
    }
    else if (currentStep === 1) {
      const hasContactMethod = Object.values(formData.contactMethods).some(method => method.selected);
      if (!hasContactMethod) {
        newErrors.contactMethods = 'At least one contact method is required';
      }

      Object.entries(formData.contactMethods).forEach(([method, details]) => {
        if (details.selected && !details.value.trim()) {
          newErrors[`contactMethod_${method}`] = `${method} value is required`;
        }
      });

      if (!formData.howFound.trim()) {
        newErrors.howFound = 'This field is required';
      }
    }
    else if (currentStep === 2) {
      if (formData.isNetworkingCritical === null) {
        newErrors.isNetworkingCritical = 'Please select Yes or No';
      }

      Object.entries(formData.networkingEvents).forEach(([event, details]) => {
        if (details.selected && details.count === '') {
          newErrors[`networkingEvent_${event}`] = 'Please specify how many';
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateAllSteps = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (formData.isRepresentingBusiness) {
      if (!formData.businessName.trim()) {
        newErrors.businessName = 'Business name is required';
      }
      if (!formData.businessNature.trim()) {
        newErrors.businessNature = 'Business nature is required';
      }
      if (!formData.businessLocation.trim()) {
        newErrors.businessLocation = 'Business location is required';
      }
      if (!formData.staffCount) {
        newErrors.staffCount = 'Staff count is required';
      }
      if (!formData.contractorCount) {
        newErrors.contractorCount = 'Contractor count is required';
      }
    }

    const hasContactMethod = Object.values(formData.contactMethods).some(method => method.selected);
    if (!hasContactMethod) {
      newErrors.contactMethods = 'At least one contact method is required';
    }

    Object.entries(formData.contactMethods).forEach(([method, details]) => {
      if (details.selected && !details.value.trim()) {
        newErrors[`contactMethod_${method}`] = `${method} value is required`;
      }
    });

    if (!formData.howFound.trim()) {
      newErrors.howFound = 'This field is required';
    }

    if (formData.isNetworkingCritical === null) {
      newErrors.isNetworkingCritical = 'Please select Yes or No';
    }

    Object.entries(formData.networkingEvents).forEach(([event, details]) => {
      if (details.selected && details.count === '') {
        newErrors[`networkingEvent_${event}`] = 'Please specify how many';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const moveToNextStep = () => {
    if (validateCurrentStep()) {
      if (currentStep < totalSteps - 1) {
        setCurrentStep(prev => prev + 1);
        // window.scrollTo(0, 0);
      } else {
        if (validateAllSteps()) {
          setShowConfirmation(true);
        } else {
          // toast({
          //   title: "Form Validation Error",
          //   description: "Please correct the highlighted fields before submitting.",
          //   variant: "destructive"
          // });
          
          const firstErrorField = document.querySelector('.error-message');
          if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }
    } else {
      // toast({
      //   title: "Please Complete Required Fields",
      //   description: "Some required fields are missing or invalid.",
      //   variant: "destructive"
      // });
      
      const firstErrorField = document.querySelector('.error-message');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const moveToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
      // window.scrollTo(0, 0);
    }
  };

  const handleSubmit = async () => {
    console.log('Form submitted:', formData);
    
    const response = await addRegisterPanForm(formData);
    console.log(response);

    if (response?.success) {  // Check if response indicates success
        setShowConfirmation(false);
        onSubmitComplete();
    } else {
        toast.error(response?.message || "Something went wrong. Please try again.", { duration: 5000 });
    }
};


  const renderPersonalAndBusinessInfo = () => (
    <>
      <FormSection title="Personal Information">
        <div className="space-y-4">
          <div>
            <label htmlFor="name" className="input-label flex items-center gap-1">
              <User size={16} />
              Name (as represented on LinkedIn)
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={`form-input ${errors.name ? 'border-red-500' : ''}`}
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Your full name"
            />
            {errors.name && <p className="text-red-500 text-xs mt-1 error-message">{errors.name}</p>}
          </div>
          
          <div>
            <div className="mb-2">
              <label className="input-label">Are you representing a business?</label>
              <div className="flex gap-4 mt-1">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="business-yes"
                    name="isRepresentingBusiness"
                    value="true"
                    checked={formData.isRepresentingBusiness === true}
                    onChange={handleRadioChange}
                    className="form-checkbox"
                  />
                  <label htmlFor="business-yes" className="ml-2 text-sm text-gray-700">Yes</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="business-no"
                    name="isRepresentingBusiness"
                    value="false"
                    checked={formData.isRepresentingBusiness === false}
                    onChange={handleRadioChange}
                    className="form-checkbox"
                  />
                  <label htmlFor="business-no" className="ml-2 text-sm text-gray-700">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormSection>

      {formData.isRepresentingBusiness && (
        <FormSection title="Business Information">
          <div className="space-y-4">
            <div>
              <label htmlFor="businessName" className="input-label flex items-center gap-1">
                <Building size={16} />
                Business Name
              </label>
              <input
                type="text"
                id="businessName"
                name="businessName"
                className={`form-input ${errors.businessName ? 'border-red-500' : ''}`}
                value={formData.businessName}
                onChange={handleInputChange}
                placeholder="Your business name"
              />
              {errors.businessName && <p className="text-red-500 text-xs mt-1 error-message">{errors.businessName}</p>}
            </div>
            
            <div>
              <label htmlFor="businessNature" className="input-label">Nature of Business</label>
              <input
                type="text"
                id="businessNature"
                name="businessNature"
                className={`form-input ${errors.businessNature ? 'border-red-500' : ''}`}
                value={formData.businessNature}
                onChange={handleInputChange}
                placeholder="Describe your business"
              />
              {errors.businessNature && <p className="text-red-500 text-xs mt-1 error-message">{errors.businessNature}</p>}
            </div>
            
            <div>
              <label htmlFor="businessLocation" className="input-label flex items-center gap-1">
                <MapPin size={16} />
                Business Location
              </label>
              <input
                type="text"
                id="businessLocation"
                name="businessLocation"
                className={`form-input ${errors.businessLocation ? 'border-red-500' : ''}`}
                value={formData.businessLocation}
                onChange={handleInputChange}
                placeholder="Where is your business primarily located?"
              />
              {errors.businessLocation && <p className="text-red-500 text-xs mt-1 error-message">{errors.businessLocation}</p>}
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="staffCount" className="input-label flex items-center gap-1">
                  <Users size={16} />
                  Staff Count
                </label>
                <select
                  id="staffCount"
                  name="staffCount"
                  className={`form-input ${errors.staffCount ? 'border-red-500' : ''}`}
                  value={formData.staffCount}
                  onChange={handleInputChange}
                >
                  <option value="">Select...</option>
                  <option value="1">1</option>
                  <option value="1-5">1-5</option>
                  <option value="6-10">6-10</option>
                  <option value="11-20">11-20</option>
                  <option value="21-50">21-50</option>
                  <option value="over 50">Over 50</option>
                </select>
                {errors.staffCount && <p className="text-red-500 text-xs mt-1 error-message">{errors.staffCount}</p>}
              </div>
              
              <div>
                <label htmlFor="contractorCount" className="input-label">Contractors Per Quarter</label>
                <select
                  id="contractorCount"
                  name="contractorCount"
                  className={`form-input ${errors.contractorCount ? 'border-red-500' : ''}`}
                  value={formData.contractorCount}
                  onChange={handleInputChange}
                >
                  <option value="">Select...</option>
                  <option value="None">None</option>
                  <option value="Less than 5">Less than 5</option>
                  <option value="More than 5">More than 5</option>
                </select>
                {errors.contractorCount && <p className="text-red-500 text-xs mt-1 error-message">{errors.contractorCount}</p>}
              </div>
            </div>
          </div>
        </FormSection>
      )}
    </>
  );

  const renderContactInfo = () => (
    <FormSection title="Contact Information">
      <div className="space-y-4">
        <div>
          <label className="input-label">What is your preferred method(s) of being contacted?</label>
          <p className="text-sm text-gray-500 mb-3">Tick any that apply</p>
          
          {errors.contactMethods && (
            <p className="text-red-500 text-xs mb-2 error-message">{errors.contactMethods}</p>
          )}
          
          <div className="space-y-1">
            {Object.entries(formData.contactMethods).map(([method, details]) => (
              <div key={method}>
                <ContactOption 
                  id={`contact-${method}`}
                  label={method}
                  checked={details.selected}
                  onChange={() => handleContactMethodChange(method)}
                  value={method}
                  inputValue={details.value}
                  onInputChange={(e) => handleContactValueChange(method, e.target.value)}
                />
                {details.selected && errors[`contactMethod_${method}`] && (
                  <p className="text-red-500 text-xs ml-7 mt-1 error-message">{errors[`contactMethod_${method}`]}</p>
                )}
              </div>
            ))}
          </div>
        </div>
        
        <div>
          <label htmlFor="howFound" className="input-label flex items-center gap-1">
            <Link size={16} />
            How did you find out about PAN or/and from whom?
          </label>
          <input
            type="text"
            id="howFound"
            name="howFound"
            className={`form-input ${errors.howFound ? 'border-red-500' : ''}`}
            value={formData.howFound}
            onChange={handleInputChange}
            placeholder="Please let us know"
          />
          {errors.howFound && <p className="text-red-500 text-xs mt-1 error-message">{errors.howFound}</p>}
        </div>
      </div>
    </FormSection>
  );

  const renderNetworkingInfo = () => (
    <FormSection title="About Your Networking">
      <div className="space-y-4">
        <div>
          <label className="input-label">Will Professional Networking be even more critical in the near future?</label>
          <div className="flex gap-4 mt-1">
            <div className="flex items-center">
              <input
                type="radio"
                id="networking-yes"
                name="isNetworkingCritical"
                value="true"
                checked={formData.isNetworkingCritical === true}
                onChange={handleRadioChange}
                className="form-checkbox"
              />
              <label htmlFor="networking-yes" className="ml-2 text-sm text-gray-700">Yes</label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="networking-no"
                name="isNetworkingCritical"
                value="false"
                checked={formData.isNetworkingCritical === false}
                onChange={handleRadioChange}
                className="form-checkbox"
              />
              <label htmlFor="networking-no" className="ml-2 text-sm text-gray-700">No</label>
            </div>
          </div>
          {errors.isNetworkingCritical && (
            <p className="text-red-500 text-xs mt-1 error-message">{errors.isNetworkingCritical}</p>
          )}
        </div>
        
        <div>
          <label className="input-label flex items-center gap-1">
            <Calendar size={16} />
            Please Tick Any That Apply
          </label>
          
          <div className="space-y-1 mt-2">
            {Object.entries(formData.networkingEvents).map(([event, details]) => (
              <div key={event}>
                <NetworkingEventOption
                  id={`event-${event}`}
                  label={event}
                  checked={details.selected}
                  onChange={() => handleNetworkingEventChange(event)}
                  value={event}
                  countValue={details.count}
                  onCountChange={(e) => handleEventCountChange(event, e.target.value)}
                />
                {details.selected && errors[`networkingEvent_${event}`] && (
                  <p className="text-red-500 text-xs ml-7 mt-1 error-message">{errors[`networkingEvent_${event}`]}</p>
                )}
              </div>
            ))}
          </div>
        </div>
        
        <div>
          <label htmlFor="additionalComments" className="input-label">Additional Comments</label>
          <textarea
            id="additionalComments"
            name="additionalComments"
            rows="4"
            className="form-input"
            value={formData.additionalComments}
            onChange={handleInputChange}
            placeholder="Any other information or comments you'd like to tell us..."
            maxLength="250"
          ></textarea>
          <p className="text-xs text-gray-500 mt-1">
            {formData.additionalComments.length}/250 characters
          </p>
        </div>
      </div>
    </FormSection>
  );

  const renderReviewAndSubmit = () => (
    <FormSection title="Review & Submit">
      <div className="space-y-6">
        <p className="text-center text-gray-700">Please review your information before submitting.</p>
        
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="font-semibold text-purple-700 mb-2">Personal Information</h3>
          <p><span className="font-medium">Name:</span> {formData.name}</p>
          <p><span className="font-medium">Representing Business:</span> {formData.isRepresentingBusiness ? 'Yes' : 'No'}</p>
          
          {formData.isRepresentingBusiness && (
            <>
              <h3 className="font-semibold text-purple-700 mt-4 mb-2">Business Information</h3>
              <p><span className="font-medium">Business Name:</span> {formData.businessName}</p>
              <p><span className="font-medium">Nature of Business:</span> {formData.businessNature}</p>
              <p><span className="font-medium">Business Location:</span> {formData.businessLocation}</p>
              <p><span className="font-medium">Staff Count:</span> {formData.staffCount}</p>
              <p><span className="font-medium">Contractor Count:</span> {formData.contractorCount}</p>
            </>
          )}
          
          <h3 className="font-semibold text-purple-700 mt-4 mb-2">Contact Information</h3>
          <p><span className="font-medium">Preferred Contact Methods:</span></p>
          <ul className="list-disc pl-5">
            {Object.entries(formData.contactMethods).map(([method, details]) => (
              details.selected && (
                <li key={method}>
                  {method}: {details.value}
                </li>
              )
            ))}
          </ul>
          <p className="mt-2"><span className="font-medium">How you found PAN:</span> {formData.howFound}</p>
          
          <h3 className="font-semibold text-purple-700 mt-4 mb-2">Networking Information</h3>
          <p>
            <span className="font-medium">Is networking critical in the future?</span> 
            {formData.isNetworkingCritical === null ? 'Not specified' : formData.isNetworkingCritical ? 'Yes' : 'No'}
          </p>
          
          <p className="font-medium mt-2">Networking Events:</p>
          <ul className="list-disc pl-5">
            {Object.entries(formData.networkingEvents).map(([event, details]) => (
              details.selected && (
                <li key={event}>
                  {event}: {details.count} per {event.includes('Local') ? 'month' : 'year'}
                </li>
              )
            ))}
          </ul>
          
          {formData.additionalComments && (
            <>
              <p className="font-medium mt-2">Additional Comments:</p>
              <p className="italic">{formData.additionalComments}</p>
            </>
          )}
        </div>
        
        <div className="text-center">
          <button
            type="button"
            onClick={() => setShowConfirmation(true)}
            className="px-6 py-3 bg-panglobal-primary text-white rounded-md font-medium shadow-panglobal hover:bg-panglobal-secondary focus:outline-none focus:ring-2 focus:ring-panglobal-primary focus:ring-opacity-50 transition-colors"
          >
            Submit Form
          </button>
        </div>
      </div>
    </FormSection>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return renderPersonalAndBusinessInfo();
      case 1:
        return renderContactInfo();
      case 2:
        return renderNetworkingInfo();
      case 3:
        return renderReviewAndSubmit();
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-12">
      <FormHeader />
      
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <FormStepper currentStep={currentStep} totalSteps={totalSteps} />
        
        <form onSubmit={(e) => e.preventDefault()}>
          {renderStepContent()}
          
          <div className="flex justify-between mt-8">
            {currentStep > 0 && (
              <button
                type="button"
                onClick={moveToPreviousStep}
                className="flex items-center gap-2 px-6 py-3 bg-gray-200 text-gray-800 rounded-md font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-panglobal-primary focus:ring-opacity-50 transition-colors"
              >
                <ArrowLeft size={20} />
                Previous
              </button>
            )}
            
            <div className="ml-auto">
              {currentStep < 3 && (
                <button
                  type="button"
                  onClick={moveToNextStep}
                  className="flex items-center gap-2 px-6 py-3 bg-panglobal-primary text-white rounded-md font-medium shadow-panglobal hover:bg-panglobal-secondary focus:outline-none focus:ring-2 focus:ring-panglobal-primary focus:ring-opacity-50 transition-colors"
                >
                  Next
                  <ArrowRight size={20} />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      
      {showConfirmation && (
        <ConfirmationModal 
          formData={formData} 
          onClose={() => setShowConfirmation(false)}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default NetworkingForm;
