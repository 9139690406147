import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Benefits from "../components/Benefits";
import MembershipTiers from "../components/MembershipTiers";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import PanIndex from "../../register-components/pages/pan-index";

const NetworkIndex = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">
        <Hero />
        <Benefits />
        <MembershipTiers />
        <br />
        <br />
        <br />
        <br />
        <PanIndex />
      </main>
      <Footer />
    </div>
  );
};

export default NetworkIndex;
