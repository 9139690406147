import React from "react";
// import { Button } from "../components/ui/button";
// import { Globe, Menu } from "lucide-react";
import logo from "../../assets/logo.png";

const Header = () => {
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <header className="w-full py-4 px-4 md:px-8 flex items-center justify-between border-b">
      <div className="flex items-center gap-2">
        <a href="/" className="logo flex items-center">
          <img src={logo} alt="Donaty Icon" width="35" height="35" />
          <span className="text-xl font-bold ml-2">PAN Global</span>
        </a>
      </div>

      <div className="hidden md:flex items-center gap-6">
        <nav>
          <ul className="flex items-center gap-6">
            <li>
              <button
                onClick={() => (window.location.href = "/")}
                className="text-sm font-medium hover:text-primary transition-colors"
              >
                Home
              </button>
            </li>
            <li>
              <a
                href="#benefits"
                className="text-sm font-medium hover:text-primary transition-colors"
              >
                Benefits
              </a>
            </li>
            <li>
              <a
                href="#tiers"
                onClick={() => scrollToSection("tiers")}
                className="text-sm font-medium hover:text-primary transition-colors"
              >
                Membership Tiers
              </a>
            </li>
          </ul>
        </nav>
        {/* <button >Join Now</button> */}
        <a
          href="#register-pan"
          onClick={() => scrollToSection("register-pan")}
          className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600"
        >
          Join Now
        </a>
      </div>

      <button className="md:hidden p-2 rounded-md hover:bg-gray-200">
        <svg
          className="h-6 w-6"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
      </button>
    </header>
  );
};

export default Header;
