
import React from 'react';
// import { Button } from "../components/ui/button";
// import { Check } from "lucide-react";
// import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";

const tierData = [
  {
    id: 1,
    name: "Tier 1 - Learning Level",
    price: "£99",
    regularPrice: "£149",
    description: "Solo Operators - Event Attendees, Organisers of Small Events, Freelancers",
    forWho: [
      "Solo Operators - Event Attendees, Organisers of Small Events, Freelancers",
      "Anyone looking to improve their understanding and outlook for professional networking",
      "Those concerned about the world of AI and what the future holds for Human Utility"
    ],
    criteria: [
      "You work alone or are a sole decision maker in your business",
      "You believe in the positive value networking can bring to others",
      "You will look to improve your knowledge of best practices"
    ],
    benefits: [
      "Educational Programme - Choose from three tracks to suit your needs",
      "Grow your Networking Prowess and Confidence",
      "Improve Your Career or Vocational Opportunities",
      "Being an Organiser with 360 vision of and at events",
      "30min onboarding get-to-know-you call",
      "Membership Badge of honour",
      "Access to the latest trends and technologies",
      "Offers and Incentives through our partner network"
    ]
  },
  {
    id: 2,
    name: "Tier 2 - Facilitators, Event Managers & Super Connectors",
    price: "£349",
    regularPrice: "£499",
    description: "SME Operators & Organisers - If you are responsible for creating networking experiences",
    forWho: [
      "SME Operators & Organisers - Creating networking experiences",
      "Those with teams that attend events on behalf of their Businesses",
      "Organisers of Small-Medium Sized Events",
      "Smaller Suppliers to Event (Such as Training, Experiential and Facilitators)"
    ],
    criteria: [
      "You work alone or as a small team with less than 15 employees",
      "Your company turns over less than £500k",
      "You create fewer than 25 events per year or under 2500 attendees per annum"
    ],
    benefits: [
      "Everything in Tier 1 for your chosen team members",
      "Additional Tracks for Hosting Excellence of Events",
      "Dedicated landing page/microsite for your business",
      "3 Licences for team members or delivery partners",
      "Peer to Peer forums at selected times throughout the year",
      "Opportunities to grow your business through partnering",
      "1 Hour problem solving Focus Groups for events",
      "Partner Discounts and Sponsorship Placement Opportunities",
      "Editorial Advances for newsletter inclusion and publicity"
    ]
  },
  {
    id: 3,
    name: "Tier 3 - Service Providers and Supply Chain",
    price: "£999",
    regularPrice: "£1499",
    description: "Enterprise Solutions: Technical Service Platforms, Market Places, Directories and Agencies",
    forWho: [
      "Enterprise Solutions: Technical Platforms, Market Places, Directories",
      "Venue groups such as syndicates, hotel groups, large event spaces",
      "Distributed Teams, Multi-Location Based businesses and Event owners"
    ],
    criteria: [
      "Your Brand can be mentioned by PAN as a member",
      "Your company is involved with over 25 events per year or 2500+ attendees",
      "One executive representative will attend quarterly executive events"
    ],
    benefits: [
      "Everything in Tier 1 and 2 for your chosen team members",
      "1 x 1 Hour Consultancy Session every 6 months worth £500",
      "Dedicated landing page & profile for your business",
      "9 Licences for team members or delivery partners",
      "Partner Discounts, Referral Bonuses and Sponsorship Opportunities",
      "Editorial Advances for newsletter inclusion and publicity",
      "First Refusal on Sponsorship Initiatives",
      "2 x Passes to Annual Dinner or thought-leader events",
      "Additional Benefits through our partners based on suitability"
    ]
  }
];

const CheckIcon = () => (
  <svg className="h-5 w-5 text-purple-500 flex-shrink-0" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M20 6L9 17l-5-5"></path>
  </svg>
);

const TierCard = ({ tier }) => {
  return (
    <div id="tiers" className={`tier-card flex flex-col h-full bg-white  rounded-lg shadow-sm border ${tier.id === 2 ? 'border-primary border-2 shadow-lg relative' : ''}`}>
      {tier.id === 2 && (
        <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-purple-700 text-white text-xs px-3 py-1 rounded-full">
          Most Popular
        </div>
      )}
      <div className={`p-4 ${tier.id === 2 ? 'bg-purple-400 text-white rounded-t-md' : ''}`}>
        <h3 className="text-xl font-bold">{tier.name}</h3>
        <p className={tier.id === 2 ? 'text-white/80' : ''}>{tier.description}</p>
        <div className="mt-4">
          <span className="text-3xl font-bold">{tier.price}</span>
          <span className="text-sm line-through ml-2">{tier.regularPrice}</span>
          <p className="text-sm mt-1">Early Bird Annual Price</p>
        </div>
      </div>
      <div className="flex-grow p-4">
        <div className="mb-6">
          <h4 className="font-semibold mb-2">Who's it for:</h4>
          <ul className="space-y-2">
            {tier.forWho.map((item, i) => (
              <li key={i} className="flex gap-2 text-sm">
                <CheckIcon />
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
        
        <div className="mb-6">
          <h4 className="font-semibold mb-2">Membership Criteria:</h4>
          <ul className="space-y-2">
            {tier.criteria.map((item, i) => (
              <li key={i} className="flex gap-2 text-sm">
                <CheckIcon />
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
        
        <div>
          <h4 className="font-semibold mb-2">What We Provide You:</h4>
          <ul className="space-y-2">
            {tier.benefits.map((item, i) => (
              <li key={i} className="flex gap-2 text-sm">
                <CheckIcon />
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="p-4">
        <button className={`w-full px-4 py-2 rounded ${tier.id === 2 ? 'bg-purple-500 text-white hover:bg-purple-500' : 'border border-gray-300 text-gray-700 hover:bg-gray-100'}`}>
          Join Now
        </button>
      </div>
    </div>
  );
};

const MembershipTiers = () => {
  return (
    <section id="tiers" className="py-12 md:py-20 px-4 md:px-8 bg-accent">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Membership Tiers</h2>
          <p className="text-lg text-muted-foreground max-w-3xl mx-auto">
            Choose the tier that best suits your needs and start enjoying the benefits of being part of the PAN Global Network.
          </p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {tierData.map((tier) => (
            <TierCard key={tier.id} tier={tier} />
          ))}
        </div>

        <div className="mt-8 text-center text-sm text-muted-foreground">
          <p>All Tier prices can be purchased as one or two year packages to benefit early supporters at the price advertised at the time of purchase.</p>
          <p className="mt-2">*All offers are subject to change and prices can be reviewed at any point in time but will be held at the price at time of enquiry for 1 additional month</p>
        </div>
      </div>
    </section>
  );
};

export default MembershipTiers;
