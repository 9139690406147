import React from "react";
// import { Button } from "../components/ui/button";

const Hero = () => {
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section className="py-12 md:py-20 px-4 md:px-8">
      <div className="max-w-6xl mx-auto text-center">
        <h1 className="text-3xl md:text-5xl font-bold mb-6">
          Join PAN on whichever Tier <br /> best suits your potential
        </h1>
        <p className="text-lg md:text-xl text-muted-foreground mb-8 max-w-3xl mx-auto">
          We are here to support our members and drive improvements.
          <br />
          Your support is very much appreciated thank you.
        </p>
        {/* <div className="flex flex-wrap items-center justify-center gap-4">
          <a
            href="#tiers"
            onClick={() => scrollToSection("tiers")}
            className="bg-purple-500 hover:bg-purple-600 text-white px-8 py-6 text-lg rounded-md"
          >
            Explore Membership Tiers
          </a>

          <button className="border border-gray-300 text-gray-700 px-8 py-6 text-lg rounded-md hover:bg-gray-100">
            Learn More
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default Hero;
