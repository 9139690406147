import React from "react";
import { Globe } from "lucide-react";
import logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-8 md:py-12 px-4 md:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="flex items-center gap-2 mb-6 md:mb-0">
            <a href="/" className="logo flex items-center">
              <img src={logo} alt="Donaty Icon" width="35" height="35" />
              <span className="text-xl font-bold ml-2">PAN Global</span>
            </a>
          </div>

          <div className="flex flex-wrap justify-center gap-8">
            <div>
              <h4 className="font-semibold mb-3">Quick Links</h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-sm hover:text-primary transition-colors"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#benefits"
                    className="text-sm hover:text-primary transition-colors"
                  >
                    Benefits
                  </a>
                </li>
                <li>
                  <a
                    href="#tiers"
                    className="text-sm hover:text-primary transition-colors"
                  >
                    Membership
                  </a>
                </li>
                <li>
                  <a
                    href="#contact"
                    className="text-sm hover:text-primary transition-colors"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="font-semibold mb-3">Membership</h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-sm hover:text-primary transition-colors"
                  >
                    Tier 1 - Learning Level
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-sm hover:text-primary transition-colors"
                  >
                    Tier 2 - Facilitators
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-sm hover:text-primary transition-colors"
                  >
                    Tier 3 - Service Providers
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="font-semibold mb-3">Contact</h4>
              <ul className="space-y-2">
                <li className="text-sm">adam@panglobal.network</li>
                {/* <li className="text-sm">+44 123 456 7890</li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="border-t pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm text-muted-foreground mb-4 md:mb-0">
            &copy; {new Date().getFullYear()} PAN Global Network. All rights
            reserved.
          </p>

          <div className="flex gap-4">
            <a
              href="#"
              className="text-sm text-muted-foreground hover:text-primary transition-colors"
            >
              Privacy Policy
            </a>
            <a
              href="#"
              className="text-sm text-muted-foreground hover:text-primary transition-colors"
            >
              Terms of Service
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
