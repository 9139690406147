
import React from 'react';
import { CheckCircle, Circle } from 'lucide-react';

const FormStepper = ({ currentStep, totalSteps }) => {
  return (
    <div className="w-full mb-8">
      <div className="flex justify-between items-center">
        {Array.from({ length: totalSteps }).map((_, index) => (
          <React.Fragment key={index}>
            {/* Step indicator */}
            <div className="flex flex-col items-center">
              <div className={`
                w-10 h-10 rounded-full flex items-center justify-center 
                ${index < currentStep 
                  ? 'bg-green-500 text-white' 
                  : index === currentStep 
                    ? 'bg-purple-700 text-white'
                    : 'bg-gray-200 text-gray-500'
                }
              `}>
                {index < currentStep ? (
                  <CheckCircle size={20} />
                ) : (
                  <Circle size={20} />
                )}
              </div>
              <span className="text-xs mt-2">
                {index === 0 ? 'Personal & Business' : 
                 index === 1 ? 'Contact Info' :
                 index === 2 ? 'Networking' :
                 'Review & Submit'}
              </span>
            </div>
            
            {/* Line connector (not for the last item) */}
            {index < totalSteps - 1 && (
              <div className={`
                flex-1 h-1 mx-2
                ${index < currentStep ? 'bg-green-500' : 'bg-gray-200'}
              `} />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default FormStepper;
