
import React from 'react';

const ContactOption = ({ id, label, checked, onChange, value, inputValue, onInputChange }) => {
  return (
    <div className="mb-2">
      <div className="flex items-center">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          value={value}
          className="form-checkbox"
        />
        <label htmlFor={id} className="ml-2 text-sm text-gray-700">
          {label}
        </label>
      </div>
      
      {checked && (
        <div className="mt-1 ml-7">
          <input
            type="text"
            value={inputValue || ''}
            onChange={onInputChange}
            className="form-input"
            placeholder={`Enter your ${label.toLowerCase()}`}
          />
        </div>
      )}
    </div>
  );
};

export default ContactOption;
