
import React from 'react';
import { 
  Check, 
  X
} from 'lucide-react';

const ConfirmationModal = ({ formData, onClose, onSubmit }) => {
  if (!formData) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white p-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-panglobal-dark">Review Your Information</h2>
          <button onClick={onClose} className="p-1 rounded-full hover:bg-gray-100">
            <X size={20} />
          </button>
        </div>
        
        <div className="p-6">
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-panglobal-primary mb-2">Basic Information</h3>
              <div className="space-y-2 text-sm">
                <p><span className="font-medium">Name:</span> {formData.name}</p>
                <p><span className="font-medium">Representing a business:</span> {formData.isRepresentingBusiness ? 'Yes' : 'No'}</p>
                {formData.isRepresentingBusiness && (
                  <>
                    <p><span className="font-medium">Business Name:</span> {formData.businessName}</p>
                    <p><span className="font-medium">Business Nature:</span> {formData.businessNature}</p>
                    <p><span className="font-medium">Business Location:</span> {formData.businessLocation}</p>
                    <p><span className="font-medium">Staff Count:</span> {formData.staffCount}</p>
                    <p><span className="font-medium">Contractors per Quarter:</span> {formData.contractorCount}</p>
                  </>
                )}
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-panglobal-primary mb-2">Contact Methods</h3>
              <div className="space-y-2 text-sm">
                {Object.entries(formData.contactMethods).map(([method, details]) => 
                  details.selected && (
                    <p key={method}><span className="font-medium">{method}:</span> {details.value}</p>
                  )
                )}
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-panglobal-primary mb-2">How You Found Us</h3>
              <p className="text-sm">{formData.howFound}</p>
            </div>

            <div>
              <h3 className="text-lg font-medium text-panglobal-primary mb-2">Networking</h3>
              <div className="space-y-2 text-sm">
                <p><span className="font-medium">Is networking critical in the future?</span> {formData.isNetworkingCritical ? 'Yes' : 'No'}</p>
                
                {Object.entries(formData.networkingEvents).map(([type, details]) => 
                  details.selected && (
                    <p key={type}><span className="font-medium">{type}:</span> {details.count} per {type.includes('Local') ? 'month' : 'year'}</p>
                  )
                )}
              </div>
            </div>

            {formData.additionalComments && (
              <div>
                <h3 className="text-lg font-medium text-panglobal-primary mb-2">Additional Comments</h3>
                <p className="text-sm">{formData.additionalComments}</p>
              </div>
            )}
          </div>
        </div>
        
        <div className="sticky bottom-0 bg-white p-4 border-t border-gray-200 flex justify-end gap-4">
          <button 
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium shadow-sm hover:bg-gray-50"
          >
            Edit
          </button>
          <button 
            onClick={onSubmit}
            className="flex items-center gap-1 px-4 py-2 bg-panglobal-primary text-white rounded-md text-sm font-medium shadow-sm hover:bg-panglobal-secondary"
          >
            <Check size={16} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
