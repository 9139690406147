
import React from 'react';
import { CheckCircle } from 'lucide-react';

const SubmissionComplete = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 flex flex-col justify-center">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-panglobal p-8 text-center">
          <div className="flex justify-center mb-6">
            <CheckCircle size={64} className="text-green-500" />
          </div>
          <h1 className="text-2xl sm:text-3xl font-bold text-panglobal-dark mb-4">Thank You!</h1>
          <p className="text-lg text-gray-600 mb-8">
            Your form has been successfully submitted. We will be in touch shortly to discuss your answers and provide further information.
          </p>
          <div className="border-t border-gray-200 pt-6 mt-6">
            <p className="text-panglobal-primary font-medium">
              PAN Global Network - Connecting Professionals Worldwide
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionComplete;
