import React from "react";
import "../styles/ActionSection.css";

const ActionSection = () => {
    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
      };
    return (
        <div className="action-section">
            <div className="action-content">
                <div className="action-text">
                    <div>
                        <h4 className="action-subtitle">Empowering the Digital Future</h4>
                        <h1 className="action-title">
                            Cutting-Edge Networking Solutions for Global Connectivity and Innovation
                        </h1>
                    </div>

                    <a href="membership" className="donate-button">Become a Member</a>
                </div>
            </div>
            <div className="stats-section">
                <div className="stat-item">
                    <h2 className="stat-value">500+</h2>
                    <p className="stat-label">Event Organisers</p>
                </div>
                <div className="stat-item">
                    <h2 className="stat-value">1000</h2>
                    <p className="stat-label">Member Target</p>
                </div>
                <div className="stat-item">
                    <h2 className="stat-value">100%</h2>
                    <p className="stat-label"> Trust in Our Vision</p>
                </div>
                <div className="stat-item">
                    <h2 className="stat-value">50</h2>
                    <p className="stat-label"> Partnership Potentials</p>
                </div>
            </div>

        </div>
    );
};

export default ActionSection;
