import React, { useState } from 'react';
import '../styles/ContactUs.css';  // Import the external CSS file
import { addContactUs } from '../services/api.service'; // Import the API service

const ContactUs = () => {
  const [formData, setFormData] = useState({
    sName: '',
    sEmail: '',
    sDescription: '',
  });

  const [responseMessage, setResponseMessage] = useState(''); // For success or error messages

  const handleInputChange = (e) => {
    const { name, value } = e.target;  // Use `name` from the event target to update the form
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send data to the backend
      const response = await addContactUs(formData.sName, formData.sEmail, formData.sDescription);

      if (response?.message) {
        // setResponseMessage('Message sent successfully! Redirecting to your email client...');

        // Send form data to the user's default email client
        // redirectToEmail(formData.sName, formData.sEmail, formData.sDescription);

        setFormData({ sName: '', sEmail: '', sDescription: '' }); // Reset form after successful submission
      } else {
        setResponseMessage('Failed to send message. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // setResponseMessage('An error occurred. Please try again later.');
    }
  };

  // Function to redirect to the email client
  const redirectToEmail = (name, email, description) => {
    const recipientEmail = 'adam@panglobal.network'; // Always send to this email
    const subject = encodeURIComponent('Contact Us Inquiry'); // Subject of the email
    const body = encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nMessage: ${description}`
    ); // Body of the email

    // Create mailto link and redirect user to email client
    window.location.href = `mailto:${recipientEmail}?subject=${subject}&body=${body}`;
  };

  return (
    <div id='contact' >
      <div className="contact-container">
        <div className="contact-form">
          <h2 className='contact-subtitle'>Contact Us</h2>
          <p>Have any questions? Feel free to reach out!</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                name="sName"
                type="text"
                required
                placeholder="Your Name"
                value={formData.sName}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="sEmail"
                type="email"
                required
                placeholder="Your Email"
                value={formData.sEmail}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="sDescription"
                rows="4"
                required
                placeholder="Your Message"
                value={formData.sDescription}
                onChange={handleInputChange}
              />
            </div>
            <button type="submit" className="submit-button">Send Message</button>
          </form>

          {/* Display success or error message */}
          {responseMessage && <p className="response-message">{responseMessage}</p>}
        </div>
      </div>


      <section style={{ marginTop: '50px', width:'100%', maxWidth:'1600px',marginBottom:'50px' }} className="main-section">
        <h1>
          Join us in ensuring the future of work is bright, open to all, and in advocating the benefits of professional networking to stakeholders.
        </h1>
        <div className="main-buttons">
          <a href="membership" className="get-started-button">Become A Member</a>
        </div>
      </section>

    </div>
  );
};

export default ContactUs;
